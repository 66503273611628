const useFilter = (currentTags, keyplays) => {
  if (!currentTags.length) {
    return keyplays
  }
  const currentTagsIds = currentTags.map(tag => tag.id)

  const filteredKeyPlays = keyplays.filter(keyplay => {
    const allAssociatedTagsIds = [
      ...(keyplay.departments || []),
      ...(keyplay.industries || []),
      ...(keyplay.services2 || []),
      ...(keyplay.partners || []),
      ...(keyplay.tags || []),
    ].map(tag => tag.contentful_id)

    return currentTagsIds.every(id => allAssociatedTagsIds.includes(id))
  })

  return filteredKeyPlays
}

export default useFilter
