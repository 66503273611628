import * as R from 'ramda'

import {
  alphabeticalSortObject,
  removeDuplicate,
  removeDuplicateObject,
} from 'helpers/utils'
import {getServices} from 'helpers/services'
import {getSubServices, getTagsObject, getTopServices} from 'helpers/playbook'

import {tagsPrefix} from '../../keyrus/static/tagsPrefix'

export default function usePlaybookFiltersData(data) {
  const {filterByPartnerDefaultFilter, filterByTechnologyDefaultFilter} =
    R.pathOr(null, ['allContentfulPlaybook', 'edges', 0, 'node'], data)

  const keyplays = R.pathOr(null, ['allContentfulKeyPlay', 'nodes'], data)
  const keyplaysIds =
    keyplays && R.map(keyplay => keyplay.contentful_id, keyplays)

  const {services} = R.pathOr(null, ['contentfulCountry'], data)
  const partners = R.pathOr(null, ['contentfulCountry', 'partners'], data)
  const topLevelServices = services && services.topLevelServices

  const taggedPartners = removeDuplicate(
    R.flatten(
      R.map(
        keyplay =>
          R.map(partner => partner.name, R.pathOr('', ['partners'], keyplay)),
        keyplays,
      ),
    ),
  )
  const countryTaggedPartners =
    partners && partners.filter(value => taggedPartners.includes(value.name))

  const tagsTagsObjects = getTagsObject(keyplays, 'tags')

  const newTagsTagsObjects = removeDuplicateObject(
    R.map(
      item => ({
        id: item.contentful_id,
        url: R.concat(tagsPrefix.tag, item.name.name),
        name: item.name.name,
      }),
      tagsTagsObjects,
    ),
  )

  const tagsIndustriesObjects = getTagsObject(keyplays, 'industries')

  const newTagsIndustriesObjects = removeDuplicateObject(
    R.map(
      item => ({
        id: item.contentful_id,
        url: R.concat(tagsPrefix.industry, item.name.name),
        name: item.name.name,
      }),
      tagsIndustriesObjects,
    ),
  )

  const tagsDepartmentsObjects = getTagsObject(keyplays, 'departments')

  const newTagsDepartmentsObjects = removeDuplicateObject(
    R.map(
      item => ({
        id: item.contentful_id,
        url: R.concat(tagsPrefix.department, item.name.name),
        name: item.name.name,
      }),
      tagsDepartmentsObjects,
    ),
  )

  const tagsServicesObjects = R.flatten(
    R.map(el => R.map(e => e, R.pathOr('', ['services2'], el)), keyplays),
  )
  const newSub = getSubServices(
    tagsServicesObjects,
    getServices(data, topLevelServices),
  )
  const newTop = getTopServices(
    tagsServicesObjects,
    getServices(data, topLevelServices),
  )
  const allNewServices = alphabeticalSortObject(R.concat(newTop, newSub))
  const newTagsServicesObjects = removeDuplicateObject(
    R.map(
      item => ({
        id: item.contentful_id,
        url: R.concat(tagsPrefix.service, item.name),
        name: item.name,
      }),
      allNewServices,
    ),
  )

  const newAllPartnersObjects = alphabeticalSortObject(
    removeDuplicateObject(
      R.map(item => {
        const itemCopy = item

        itemCopy.url = R.concat(tagsPrefix.partner, item.name)
        itemCopy.id = item.contentful_id

        return itemCopy
      }, partners),
    ),
  )

  const newTagsPartnersObjects = removeDuplicateObject(
    R.map(
      item => ({
        id: item.contentful_id,
        url: R.concat(tagsPrefix.partner, item.name),
        name: item.name,
      }),
      countryTaggedPartners,
    ),
  )
  const allPartnersToggleTag = [
    {
      id: 'all-partners',
      url: 'all-partners',
      name: filterByPartnerDefaultFilter,
    },
  ]
  const allTechnologiesToggleTag = [
    {
      id: 'technology',
      url: 'technology',
      name: filterByTechnologyDefaultFilter,
    },
  ]

  const allTagsObjects = R.pipe(
    R.concat(newTagsIndustriesObjects),
    R.concat(newTagsDepartmentsObjects),
    R.concat(newAllPartnersObjects),
    R.concat(newTagsServicesObjects),
    R.concat(allTechnologiesToggleTag),
    R.concat(allPartnersToggleTag),
    R.concat(newTagsTagsObjects),
  )([])

  return {
    allTagsObjects,
    newTagsPartnersObjects,
    newTagsServicesObjects,
    newTagsDepartmentsObjects,
    newTagsIndustriesObjects,
    newTagsTagsObjects,
    newAllPartnersObjects,
    keyplaysIds,
    allPartnersToggleTag,
    allTechnologiesToggleTag,
    services,
  }
}
