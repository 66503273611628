import {createHash} from 'helpers/utils'
import {oneOf, string, func, bool} from 'prop-types'
import Button from '@mui/material/Button'
import classNames from 'classnames'
import ClearRoundedIcon from '@mui/icons-material/ClearRounded'
import React from 'react'
import useStyles from './styles'

const Tag = ({type, text, onTagClick, useNewVersion = false}) => {
  const classes = useStyles()

  const handleClick = () => {
    if (useNewVersion && onTagClick) {
      onTagClick(`${type}-${text}`)
    } else {
      createHash(`${type}-${text}`)
    }
  }

  return (
    <Button
      className={classNames(classes.tagButton, classes[type])}
      size="small"
      onClick={handleClick}
    >
      <ClearRoundedIcon className={classes.clearIcon} />
      <div>#{text}</div>
    </Button>
  )
}

Tag.propTypes = {
  onTagClick: func.isRequired,
  text: string.isRequired,
  type: oneOf(['department', 'industry', 'service', 'partner']).isRequired,
  useNewVersion: bool.isRequired,
}

export default Tag
