import {makeStyles} from '@mui/styles'

const useStyles = makeStyles(({palette, fontSizes, spacing, breakpoints}) => ({
  container: {
    display: 'flex',
    [breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  hasBackground: {
    background: palette.background.greyLight,
    padding: spacing(4),
    borderRadius: '80px 20px',
    boxShadow: '5px 5px 12px 0 rgb(51 51 51 / 20%)',
  },
  leftSide: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minWidth: '30%',
    paddingRight: spacing(8),
    paddingTop: spacing(2),
    '& > img': {
      width: '100%',
      maxWidth: 300,
    },
    [breakpoints.down('sm')]: {
      width: '100%',
      padding: 0,
    },
  },
  rightSide: {
    maxWidth: '70%',
    paddingLeft: spacing(2),
    [breakpoints.down('sm')]: {
      width: '100%',
      padding: 0,
    },
  },
  nameAndStatusContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    fontSize: fontSizes.innerTitle,
    [breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  partnershipStatusContainer: {
    display: 'flex',
    gap: 5,
    maxWidth: '15%',
  },
  partnershipStatusImage: {
    width: '100%',
    objectFit: 'contain',
    [breakpoints.down('sm')]: {
      marginBottom: spacing(1),
    },
  },
  partnershipStatusTooltipIcon: {
    fontSize: 15,
  },
  buttonContainer: {
    marginTop: spacing(5),
  },
  title: {
    fontSize: fontSizes.subTitle,
    fontFamily: 'CamptonMedium',
    [breakpoints.down('sm')]: {
      marginTop: spacing(3),
    },
  },
  text: {
    marginTop: spacing(1),
    fontFamily: 'CamptonLight',
    fontSize: fontSizes.description,
    color: palette.text.primary,
  },
  awards: {
    fontFamily: 'CamptonMedium',
    fontSize: fontSizes.description,
    whiteSpace: 'break-spaces',
    padding: '2px 5px 0 5px',
    borderRadius: 5,
    width: 'fit-content',
  },
  award0: {
    color: palette.tags.departments.color,
    backgroundColor: palette.tags.departments.backgroundColor,
  },
  award1: {
    color: palette.tertiary.main,
    backgroundColor: palette.tags.partners.backgroundColor,
  },
  award2: {
    color: palette.secondary.main,
    backgroundColor: palette.tags.industries.backgroundColor,
  },
  numbersGrid: {
    display: 'flex',
    justifyContent: 'space-between',
    textAlign: 'center',
    '&>div': {
      width: '25%',
    },
    [breakpoints.down('sm')]: {
      flexDirection: 'column',
      padding: spacing(1.5),
      paddingLeft: 0,
      '&>div': {
        width: '100%',
      },
    },
  },
  numbers: {
    color: palette.text.primary,
    fontSize: fontSizes.subTitle,
    marginBottom: 0,
    fontFamily: 'CamptonMedium',
    fontWeight: 500,
  },
}))

export default useStyles
