import React from 'react'

import {switchHeaderPropTypes} from 'helpers/propTypes'

import PartnerHeader from 'components/TechnologyPartner/Header'
import PlaybookHeader from './Header'

const SwitchHeader = ({
  partner,
  pageTitle,
  partnersTitle,
  description,
  hashConditions,
  partnersShortDescription,
  eulaLink,
  countryData,
}) => {
  const {allPartnersSelected, multiPartnersSelected, onePartnerSelected} =
    hashConditions
  const {shortDescription} = description

  const RenderHeader = () => {
    if (partner && onePartnerSelected && !multiPartnersSelected) {
      return <PartnerHeader partner={partner} countryData={countryData} />
    }
    if (allPartnersSelected) {
      return (
        <PlaybookHeader
          pageTitle={partnersTitle}
          description={partnersShortDescription}
          eulaLink={eulaLink}
          hasEulaLink
        />
      )
    }

    return (
      <PlaybookHeader pageTitle={pageTitle} description={shortDescription} />
    )
  }

  return (
    <div id="playbook">
      <RenderHeader />
    </div>
  )
}

SwitchHeader.propTypes = switchHeaderPropTypes

SwitchHeader.defaultProps = {}

export default SwitchHeader
