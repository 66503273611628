import * as R from 'ramda'

export const getTopServices = (arr, services) => {
  const topServices = arr.filter(el =>
    R.map(elem => R.map(e => e, elem), services).some(
      f => f.contentful_id === el.contentful_id,
    ),
  )

  const filteredTopServices = topServices.filter(
    (v, i, a) => a.findIndex(t => t.contentful_id === v.contentful_id) === i,
  )

  // eslint-disable-next-line fp/no-mutating-methods
  const sortedTopServices = filteredTopServices.sort((a, b) =>
    a.name.localeCompare(b.name),
  )

  return sortedTopServices
}

export const getSubServices = (arr, services) => {
  const subServices = arr.filter(el =>
    R.flatten(
      R.map(elem => R.map(e => e, elem.subLevelServices), services),
    ).some(f => f.contentful_id === el.contentful_id),
  )

  const filteredSubServices = subServices.filter(
    (v, i, a) => a.findIndex(t => t.contentful_id === v.contentful_id) === i,
  )

  // eslint-disable-next-line fp/no-mutating-methods
  const sortedSubServices = filteredSubServices.sort((a, b) =>
    a.name.localeCompare(b.name),
  )

  return sortedSubServices
}

export const getTagsObject = (countrykeyplays, label) =>
  // eslint-disable-next-line fp/no-mutating-methods
  R.sort(
    (a, b) => a.name.name.localeCompare(b.name.name),
    R.flatten(
      R.map(el => R.map(e => e, R.pathOr('', [label], el)), countrykeyplays),
    ),
  )
