import Container from '@mui/material/Container'
import React from 'react'

import {Link} from 'gatsby'
import {playbookHeaderPropTypes} from 'helpers/propTypes'
import Description from 'components/UI/Description'
import Section from 'components/UI/Section'
import Title from 'components/UI/Title'

import useIsMobile from 'hooks/useIsMobile'
import useStyles from './styles'

const PlaybookHeader = ({pageTitle, description, eulaLink, hasEulaLink}) => {
  const classes = useStyles()
  const isMobile = useIsMobile()

  return (
    <Section hasPaddingBottom={false} hasVerySmallPadding>
      <Title variant="h1" type="title" isCentered={!!isMobile}>
        {pageTitle}
      </Title>
      <div className={classes.container}>
        <Container className={classes.descriptionContainer}>
          <Description type="largeDescription">{description}</Description>
          {hasEulaLink && process.env.GATSBY_WEBSITE === 'keyrus' && (
            <Link to="/EULA">{eulaLink}</Link>
          )}
        </Container>
      </div>
    </Section>
  )
}

PlaybookHeader.propTypes = playbookHeaderPropTypes

PlaybookHeader.defaultProps = {}

export default PlaybookHeader
