import * as R from 'ramda'
import {withStyles} from '@mui/styles'
import classNames from 'classnames'
import HelpOutline from '@mui/icons-material/HelpOutline'
import React, {useEffect, useState} from 'react'
import Tooltip from '@mui/material/Tooltip'

import {technologyPartnerHeaderPropTypes} from 'helpers/propTypes'
import RawText from 'components/UI/RawText'
import RoundButton from 'components/UI/RoundButton'
import Section from 'components/UI/Section'
import useIsPartnersPage from 'hooks/useIsPartnersPage'

import useStyles from './styles'

const TechnologyPartnerHeader = ({
  partner,
  countryData,
  discoverMoreCtaDefaultLabel,
}) => {
  const classes = useStyles()
  const isPartnersPage = useIsPartnersPage()

  const [awardsColorIndex, setAwardsColorIndex] = useState(0)

  useEffect(() => {
    setAwardsColorIndex(Math.floor(Math.random() * 3))
  }, [])

  const BiggerFontTooltip = withStyles(() => ({
    tooltip: {
      fontSize: 13,
    },
  }))(Tooltip)

  const getArrayKey = (arr, source) => R.pathOr(null, arr, source)

  const getLocalizedImageForKey = key => {
    const defaultContent = getArrayKey([key, 'file'], partner)

    if (!partner[`${key}CountrySpecific`]) {
      return defaultContent
    }

    const localizedImage = partner[`${key}CountrySpecific`].find(elem =>
      elem.countries.find(
        country => country.technicalName === countryData.technicalName,
      ),
    )

    if (localizedImage) {
      return getArrayKey(['imageOrVideo', 'file'], localizedImage)
    }

    return defaultContent
  }

  const getLocalizedContentForKey = key => {
    const defaultContent = getArrayKey([key], partner)

    if (!partner[`${key}CountrySpecific`]) {
      return R.pathOr(defaultContent, [key], defaultContent)
    }

    const localizedContent = partner[`${key}CountrySpecific`].find(elem =>
      elem.countries.find(
        country => country?.technicalName === countryData?.technicalName,
      ),
    )

    if (localizedContent) {
      return localizedContent.content?.content || localizedContent.content
    }

    return R.pathOr(defaultContent, [key], defaultContent)
  }

  const externalLink = getLocalizedContentForKey('externalLink')
  const localizedLogo = getLocalizedImageForKey('logo')
  const localizedLogoUrl = R.pathOr('', ['url'], localizedLogo)
  const localizedLogoFileName = R.pathOr('', ['fileName'], localizedLogo)
  const localizedPartnershipStatusImage = getLocalizedImageForKey(
    'partnershipStatusImage',
  )
  const localizedPartnershipStatusText = getLocalizedContentForKey(
    'partnershipStatusText',
  )
  const localizedPartnershipStatusTooltip = getLocalizedContentForKey(
    'partnershipStatusTooltip',
  )
  const localizedAwards = getLocalizedContentForKey('awards')
  const localizedKpis = [1, 2, 3]
    .map(index => ({
      number: getLocalizedContentForKey(`kpi${index}Number`),
      description: getLocalizedContentForKey(`kpi${index}Description`),
    }))
    .filter(kpi => kpi.description || kpi.number)
  const kpisDisplay = {}

  switch (localizedKpis.length) {
    case 1:
      kpisDisplay.justifyContent = 'center'
      break
    case 2:
      kpisDisplay.justifyContent = 'space-evenly'
      break
    default:
      kpisDisplay.justifyContent = 'space-between'
  }
  const name = getArrayKey(['name'], partner)
  const discoverMoreCtaLabel = getArrayKey(
    ['discoverMoreCtaLabel', 'discoverMoreCtaLabel'],
    partner,
  )

  const hasRawText =
    getLocalizedContentForKey('shortDescriptionRichText') !== null

  return (
    <Section hasPaddingBottom={isPartnersPage} hasVerySmallPadding>
      <div
        className={classNames(classes.container, {
          [classes.hasBackground]: isPartnersPage,
        })}
      >
        <div className={classes.leftSide}>
          <img
            src={localizedLogoUrl}
            alt={localizedLogoFileName}
            loading="lazy"
          />
          {externalLink && (
            <div className={classes.buttonContainer}>
              <RoundButton
                externalLink
                href={externalLink}
                arrow
                isSmallText
                isNowrap
              >
                {discoverMoreCtaLabel || discoverMoreCtaDefaultLabel}
              </RoundButton>
            </div>
          )}
        </div>
        <div className={classes.rightSide}>
          <div className={classes.nameAndStatusContainer}>
            <p className={classes.title}>{name}</p>
            <div className={classes.partnershipStatusContainer}>
              {localizedPartnershipStatusImage && (
                <img
                  src={localizedPartnershipStatusImage.url}
                  alt={localizedPartnershipStatusImage.fileName}
                  className={classes.partnershipStatusImage}
                  loading="lazy"
                />
              )}
              {!localizedPartnershipStatusImage && (
                <p>{localizedPartnershipStatusText}</p>
              )}
              {localizedPartnershipStatusTooltip && (
                <BiggerFontTooltip title={localizedPartnershipStatusTooltip}>
                  <HelpOutline
                    className={classes.partnershipStatusTooltipIcon}
                  />
                </BiggerFontTooltip>
              )}
            </div>
          </div>
          <div className={classes.text}>
            {hasRawText ? (
              <RawText
                text={getLocalizedContentForKey('shortDescriptionRichText')}
              />
            ) : (
              getLocalizedContentForKey('shortDescription')
            )}
          </div>
          {localizedAwards &&
            localizedAwards
              .split('\n')
              .map(award => (
                <p
                  className={classNames(
                    classes.awards,
                    classes[`award${awardsColorIndex}`],
                  )}
                >
                  {award}
                </p>
              ))}
          {localizedKpis && (
            <div
              className={classes.numbersGrid}
              style={{justifyContent: kpisDisplay.justifyContent}}
            >
              {localizedKpis.map(kpi => (
                <div>
                  <p className={classes.numbers}>{kpi.number}</p>
                  <p className={classes.text}>{kpi.description}</p>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </Section>
  )
}

TechnologyPartnerHeader.propTypes = technologyPartnerHeaderPropTypes

TechnologyPartnerHeader.defaultProps = {
  countryData: null,
  discoverMoreCtaDefaultLabel: null,
  partner: null,
}

export default TechnologyPartnerHeader
