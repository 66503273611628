import * as R from 'ramda'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Container,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import React, {useState} from 'react'

import {partnersFilterPropTypes} from 'helpers/propTypes'
import TagButton from 'components/UI/TagButton'

import useStyles from './styles'

const PartnersFilter = ({
  currentTags,
  filterByPartnerLabel,
  filterByPartnerDefaultFilter,
  filterSeeMoreLabel,
  filterSeeLessLabel,
  tags,
  hasObjectsTags,
}) => {
  const classes = useStyles()
  const mapIndexed = R.addIndex(R.map)
  const [isAccordionOpen, setisAccordionOpen] = useState(false)

  const handleAccordionChange = panel => (event, isOpen) => {
    setisAccordionOpen(isOpen ? panel : false)
  }

  const renderTags = arr =>
    mapIndexed(
      (coreValue, index) => (
        <TagButton
          key={index}
          tagData={coreValue}
          tagDataIsObject={hasObjectsTags}
          isPartner
        />
      ),
      arr,
    )

  const verif = tags.length > 5
  const newArray = currentTags
    ? currentTags.concat(
        tags.filter(
          tag =>
            !R.map(currentTag => currentTag.name, currentTags).includes(
              tag.name,
            ),
        ),
      )
    : tags

  return (
    <>
      <div className={classes.filterLabel}>{filterByPartnerLabel}</div>
      <Container className={classes.topContainer}>
        <TagButton
          tagData={filterByPartnerDefaultFilter}
          tagDataIsObject={hasObjectsTags}
          isAll
        />
        {renderTags(R.slice(0, 6, newArray))}
      </Container>
      <div className={classes.accordionContainer}>
        <Accordion
          TransitionProps={{
            timeout: {
              appear: 300,
              enter: 300,
              exit: 0,
            },
          }}
          expanded={isAccordionOpen === true}
          onChange={handleAccordionChange(true)}
          className={classes.accordion}
        >
          {verif && (
            <AccordionSummary
              className={
                !isAccordionOpen ? classes.filterSeeMoreLabel : classes.hide
              }
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <ExpandMoreIcon className={classes.expandMoreIcon} />
              {filterSeeMoreLabel}
            </AccordionSummary>
          )}
          <AccordionDetails className={classes.accordionDetails}>
            <Container className={classes.tagsContainer}>
              {renderTags(R.slice(6, newArray.length, newArray))}
            </Container>
          </AccordionDetails>
          <AccordionSummary
            className={classes.accordionSummary}
            onClick={handleAccordionChange(false)}
            aria-controls="panel1a-content"
            id="panel2a-header"
          >
            <div className={classes.filterSeeMoreLabel}>
              <ExpandMoreIcon className={classes.expandLessIcon} />
              {filterSeeLessLabel}
            </div>
          </AccordionSummary>
        </Accordion>
      </div>
    </>
  )
}

PartnersFilter.propTypes = partnersFilterPropTypes

PartnersFilter.defaultProps = {}

export default PartnersFilter
