const tagsPrefix = {
  service: 'service-',
  industry: 'industry-',
  department: 'department-',
  partner: 'partner-',
  tag: 'tag-',
}

module.exports = {
  tagsPrefix,
}
