import * as R from 'ramda'
import {Card, CardContent, Container, Grid} from '@mui/material'
import {Link} from 'gatsby'
import React, {useEffect, useRef, useState} from 'react'

import {createHash, createKlsHash, scrollTop} from 'helpers/utils'
import {keyplaysPropTypes} from 'helpers/propTypes'
import InfiniteScrollObserver from 'components/UI/InfiniteScrollObserver'
import LoadMore from 'components/UI/LoadMore'
import Tag from 'components/PlayBook/Tag'
import useFilter from 'hooks/useFilter'
import useIsBrowser from 'hooks/useIsBrowser'
import useIsMobile from 'hooks/useIsMobile'
import useObserver from 'hooks/useObserver'

import useStyles from './styles'

const Keyplays = ({
  allContentfulPartner,
  keyplays,
  currentTags,
  filterSeeMoreLabel,
  hashConditions,
}) => {
  const itemsToRender = useFilter(currentTags, keyplays)
  const classes = useStyles()
  const loadRef = useRef()
  const mobile = useIsMobile()
  const mapIndexed = R.addIndex(R.map)
  const [limit, setLimit] = useState(10)
  const [loadMore, setLoadMore] = useState(false)
  const hasMoreItems = limit <= R.length(itemsToRender)
  const hasSelectedTags = currentTags.length > 0

  const {allPartnersSelected} = hashConditions

  const loadMoreItems = () => {
    if (hasMoreItems) {
      setLoadMore(true)

      setTimeout(() => {
        setLimit(val => val + 12)
        setLoadMore(false)
      }, 1000)
    }
  }

  const [infiniteScroll, setInfiniteScroll] = useObserver(
    loadRef,
    loadMoreItems,
  )

  const handleLoadMore = () => {
    setInfiniteScroll(true)
    loadMoreItems()
  }

  useEffect(
    () => () => {
      setLimit(12)

      if (useIsBrowser) {
        if (hasSelectedTags) {
          setInfiniteScroll(false)
        }
      }
    },
    [currentTags],
  )

  const handleClick = coreValue => {
    if (process.env.GATSBY_WEBSITE === 'keyrus') {
      createHash(coreValue.url)
    }
    if (process.env.GATSBY_WEBSITE === 'kls') {
      createKlsHash(coreValue.url)
    }
    scrollTop()
  }

  const renderPartners = () =>
    mapIndexed((coreValue, index) => {
      const {logo, name} = coreValue
      const logoUrl = R.pathOr(' ', ['file', 'url'], logo)

      return (
        logo && (
          <Grid
            item
            key={index}
            xs={12}
            sm={6}
            md={4}
            style={{
              paddingLeft: !mobile && 0,
              paddingRight: !mobile && 24,
            }}
          >
            <Card
              onClick={() => handleClick(coreValue)}
              className={classes.partnersCard}
            >
              <div className={classes.imageContainer}>
                <img src={logoUrl} alt={name} loading="lazy" />
              </div>
              <CardContent className={classes.cardContent}>
                <div className={classes.partnerTitle}>{name}</div>
              </CardContent>
            </Card>
          </Grid>
        )
      )
    }, allContentfulPartner)

  const renderKeyplayCards = () =>
    mapIndexed((coreValue, index) => {
      const {image, slug, title} = coreValue
      const imageUrl = R.pathOr('', ['file', 'url'], image)
      const titleText = R.pathOr('', ['title'], title)

      return (
        <Grid
          item
          key={index}
          xs={12}
          sm={6}
          md={4}
          style={{
            paddingLeft: 0,
            paddingRight: !mobile ? 24 : 0,
          }}
        >
          <Link to={`../playbook/${slug}`} className={classes.cardLink}>
            <Card className={classes.card}>
              <div>
                <img
                  src={`${imageUrl}?w=260&h=200&q=100&fit=fill&r=4&fm=webp`}
                  alt={titleText}
                  loading="lazy"
                />
              </div>
              <CardContent className={classes.cardContent}>
                <div className={classes.cardTitle}>{titleText}</div>
              </CardContent>
            </Card>
          </Link>
        </Grid>
      )
    }, itemsToRender.slice(0, limit))

  return (
    <main className={classes.main}>
      <div className={classes.tagsContainer}>
        {R.map(tag => {
          const [type] = tag.url.split('-')

          return <Tag text={tag.name} type={type} />
        }, currentTags)}
      </div>
      <Container className={classes.cardGrid} maxWidth="md">
        <Grid
          container
          spacing={4}
          style={{margin: 0, width: mobile && '100%'}}
        >
          {allPartnersSelected ? renderPartners() : renderKeyplayCards()}
        </Grid>
      </Container>
      {hasMoreItems && (
        <LoadMore
          label={filterSeeMoreLabel}
          loadMore={loadMore}
          hasMoreItems={hasMoreItems}
          infiniteScroll={infiniteScroll}
          handleLoadMore={handleLoadMore}
          hasPaddingTop
        />
      )}
      <InfiniteScrollObserver show={infiniteScroll} Ref={loadRef} />
    </main>
  )
}

Keyplays.propTypes = keyplaysPropTypes

Keyplays.defaultProps = {}

export default Keyplays
