import {makeStyles} from '@mui/styles'

const useStyles = makeStyles(({palette, spacing, fontSizes}) => ({
  tagButton: {
    margin: spacing(0.5),
    marginLeft: 0,
    borderRadius: 30,
    border: `1px solid ${palette.tagButton.border}`,
    color: palette.text.grey,
    fontSize: fontSizes.tagButton,
    padding: spacing(1, 2),
    fontWeight: 'normal',
    fontFamily: 'CamptonBook',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    whiteSpace: 'pre',
    textTransform: 'none',
  },
  isActive: {
    border: `1px solid ${palette.primary.main}`,
    color: 'white',
    padding: spacing(1, 2),
    backgroundColor: palette.primary.main,
    '&:hover': {
      backgroundColor: palette.primary.main,
    },
  },
  accordionContainer: {
    width: '100%',
  },
  topContainer: {
    marginLeft: 0,
    paddingLeft: 0,
  },
  filterContainer: {
    padding: 0,
    marginLeft: 0,
  },
  mobileAccordion: {
    border: 'none',
    boxShadow: 'none',
    padding: 0,
  },
  mobileAccordionSummary: {
    fontWeight: 700,
    padding: spacing(1, 0),
  },
  filterLabel: {
    fontWeight: 500,
    fontSize: fontSizes.description,
    fontFamily: 'CamptonMedium',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    margin: spacing(2, 0),
  },
  accordion: {
    border: 'none',
    boxShadow: 'none',
    padding: 0,
  },
  accordionSummary: {
    padding: 0,
  },
  accordionDetails: {
    padding: 0,
  },
  expandMoreIcon: {
    verticalAlign: 'middle',
  },
  expandLessIcon: {
    verticalAlign: 'middle',
    transform: 'rotate(180deg)',
  },
  filterSeeMoreLabel: {
    padding: 0,
    fontWeight: 'normal',
    fontSize: 16,
    fontFamily: 'CamptonBook',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: palette.primary.main,
  },
  hide: {
    display: 'none',
  },
  tagsContainer: {
    paddingLeft: 0,
    marginLeft: 0,
  },
}))

export default useStyles
