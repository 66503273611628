import {makeStyles} from '@mui/styles'

const useStyles = makeStyles(({spacing, breakpoints}) => ({
  main: {
    minHeight: 1000,
  },
  tagsContainer: {
    '& button': {
      marginRight: spacing(1),
    },
  },
  cardGrid: {
    padding: 0,
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 4,
    boxShadow: 'none',
    '& div:nth-child(0)': {
      minHeight: 200,
    },
    '& img': {
      borderRadius: 4,
      objectFit: 'cover',
      height: 200,
      [breakpoints.down('sm')]: {
        marginTop: spacing(4),
        width: '100%',
      },
    },
  },
  cardLink: {
    textDecoration: 'none',
  },
  cardMedia: {
    paddingTop: '75%',
    borderRadius: 4,
  },
  cardContent: {
    flexGrow: 1,
    width: '100%',
    padding: spacing(1, 0),
  },
  cardTitle: {
    fontWeight: 500,
    fontSize: 20,
    textAlign: 'left',
    fontFamily: 'CamptonMedium',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
  },
  partnersCard: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 4,
    boxShadow: 'none',
    cursor: 'pointer',
    flexWrap: 'wrap',
  },
  partnerTitle: {
    fontWeight: 500,
    fontSize: 20,
    textAlign: 'center',
    fontFamily: 'CamptonMedium',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
  },
  imageContainer: {
    display: 'flex',
    alignItems: 'center',
    width: 200,
    height: 120,
    position: 'relative',
    justifyContent: 'center',
    margin: 'auto',
    '& img': {
      objectFit: 'cover',
      maxWidth: '70%',
      maxHeight: '70%',
    },
  },
}))

export default useStyles
