import {useEffect, useState} from 'react'

import useIsBrowser from 'hooks/useIsBrowser'

export default function usePartnerHeader(data, tags) {
  const [state, setState] = useState({})

  useEffect(() => {
    const lastPartnerSelectedTag =
      tags && tags.filter(e => e.includes('partner-')).pop()

    const lastPartnerSelectedObject = data.find(
      e => e.url === lastPartnerSelectedTag,
    )

    setState(lastPartnerSelectedObject)
  }, [useIsBrowser && window.location.hash && tags])

  return [state, setState]
}
